<template>
  <b-container fluid>
      <b-row class="text-dark">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details">
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { dateFormat } from '@/Utils/fliter.js'
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.circular = this.item
  },
  data () {
    return {
      circular: {},
      slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('barc_config.circular_name'), val: this.circular.circular_name_bn, key1: this.$t('barc_config.memo_no'), val1: this.circular.memo_no },
          { key: this.$t('globalTrans.date'), val: dateFormat(this.circular.circular_date), key1: this.$t('dae_subsidy.application_deadline'), val1: dateFormat(this.circular.deadline) }
        ]
      } else {
        return [
          { key: this.$t('barc_config.circular_name'), val: this.circular.circular_name, key1: this.$t('barc_config.memo_no'), val1: this.circular.memo_no },
          { key: this.$t('globalTrans.date'), val: dateFormat(this.circular.circular_date), key1: this.$t('dae_subsidy.application_deadline'), val1: dateFormat(this.circular.deadline) }
        ]
      }
    }
  },
  methods: {
    getCropDetails (data) {
      return data.map(item => {
        const crop = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.cropList.find(crop => crop.value === item.crop_id)
        const cropObj = {
          crop_name: crop !== undefined ? crop.text_en : '',
          crop_name_bn: crop !== undefined ? crop.text_bn : ''
        }
        return Object.assign(item, cropObj)
      })
    },
    getMaterialDetails (data) {
      return data.map(item => {
        const matType = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.agMaterialTypeList.find(mtp => mtp.value === item.material_type_id)
        const mat = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.agMaterialList.find(mt => mt.value === item.material_id)
        const matTypeObj = {
          material_type_name: matType !== undefined ? matType.text_en : '',
          material_type_name_bn: matType !== undefined ? matType.text_bn : ''
        }
        const matObj = {
          material_name: mat !== undefined ? mat.text_en : '',
          material_name_bn: mat !== undefined ? mat.text_bn : ''
        }
        return Object.assign(item, matTypeObj, matObj)
      })
    },
    getInstrumentDetails (data) {
      return data.map(item => {
        const instrument = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.instrumentList.find(inst => inst.value === item.instrument_id)
        const instrumentObj = {
          instrument_name: instrument !== undefined ? instrument.text_en : '',
          instrument_name_bn: instrument !== undefined ? instrument.text_bn : ''
        }
        return Object.assign(item, instrumentObj)
      })
    }
  }
}
</script>
<style lang="scss">
  .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
